const apiEnvironmentsByEnvironment = {
  'Dev': ['Dev'],
  'Test': ['Test'],
  'Beta': ['Beta'],
  'Prod': ['Beta', 'Prod']
};

const templateStagesByApiEnvironment = {
  'Dev': ['Dev', 'Test', 'Beta', 'Prod'],
  'Test': ['Dev', 'Test', 'Beta', 'Prod'],
  'Beta': ['Dev', 'Test', 'Beta'],
  'Prod': ['Prod'],
};

export default
{
  apiEnvironmentsByEnvironment,
  templateStagesByApiEnvironment,
};
