import dateUtils from '@/services/dateUtilities/dateUtilities';
import stringUtils from '@/services/stringUtilities/stringUtilities';
import { mapGetters, mapState } from 'vuex';
import tinyEditor from '../tinymce/tinymce-editor.vue';
import envConstants from '../../constants/envConstants';

export default {
  components: { tinyEditor },
  data() {
    return {
      headers: [
        { text: 'Sent', value: 'friendlyCreateDateTime', width: '18%' },
        { text: 'Email Tracking Id', value: 'emailTrackingId', width: '23%' },
        { text: 'From Address', value: 'fromAddress' },
        { text: 'Subject', value: 'subject' },
        { text: 'Template Stage', value: 'stage', width: '6%' },
        { text: 'Status', value: 'statusMessage' }
      ],
      trackingHeaders: [
        { text: 'Last Read Date', value: 'friendlyLastReadDate' },
        { text: 'Source Ip', value: 'sourceIpAddress' },
        { text: 'Number of Times Opened', value: 'numberOfTimesRead' }
      ],
      isVisible: 0,
      search: '',
      trackingSearch: '',
      historyDialog: false,
      currentHistoricalEmail: { body: '' },
      currentEmailBody: '',
      currentEmailBodyHtml: '',
      dates: [dateUtils.createSimpleStringFromDate(new Date()), dateUtils.createSimpleStringFromDate(dateUtils.addDaysToDate(new Date(), -6))],
      startDateText: '',
      endDateText: '',
      startDatePicker: false,
      endDatePicker: false,
      closeCounter: 0,
      minDate: '',
      historyStage: stringUtils.capitalizeFirstLetter(process.env.VUE_APP_ENV),
      historyApiEnvironment: stringUtils.capitalizeFirstLetter(process.env.VUE_APP_ENV),
      bodyInit: 'init',
      editorCancel: false,
      options: {},
      readReceiptOptions: {},
      searchEmailTrackingId: '',
      hasTo: false,
      hasCc: false,
      hasBcc: false
    };
  },
  computed: {
    ...mapGetters({
      user: ['auth/user']
    }),
    ...mapState('templates', ['notificationHistory', 'isHistoryBodyLoading', 'readReceipts', 'isLoading']),
    dateText() {
      let sortedDates = this.dates.sort();
      return sortedDates.join(' - ');
    },
    maxDate() {
      if (this.closeCounter === 1) {
        return this.dates[0];
      } else {
        let currentDate = new Date();
        let maximumDate = dateUtils.createSimpleStringFromDate(currentDate);
        return maximumDate;
      }
    },
    ...mapGetters('templates', ['getTotalRecords', 'getReadReceiptTotalRecords']),
    trimmedEmailBody() {
      return this.currentHistoricalEmail.body.replace(/<img src=\\*"https:\/\/engine.*\.notification-service.*\.foc\.zone\/pixel\/.+[^"]/g, '');
    }
  },
  watch: {
    options: {
      handler() {
        this.reloadHistoryData(false);
      },
      deep: true
    },
    readReceiptOptions: {
      handler() {
        this.loadReadReceipts();
      },
      deep: true
    }
  },
  methods: {
    async openHistoryDialog(item) {
      this.currentHistoricalEmail = item;
      this.hasTo = this.currentHistoricalEmail.recipientStatus.filter((x) => x.recipientType === 'To').length > 0;
      this.hasCc = this.currentHistoricalEmail.recipientStatus.filter((x) => x.recipientType === 'Cc').length > 0;
      this.hasBcc = this.currentHistoricalEmail.recipientStatus.filter((x) => x.recipientType === 'Bcc').length > 0;
      this.historyDialog = true;
      await this.loadReadReceipts();
    },
    closeHistoryDialog() {
      this.currentHistoricalEmail = { body: '' };
      this.currentEmailBody = null;
      this.historyDialog = false;
      this.hasTo = false;
      this.hasCc = false;
      this.hasBcc = false;
    },
    async reloadHistoryData(flag) {
      if (flag) {
        this.options.page = 1;
      }
      const { page, itemsPerPage } = this.options;
      const params = {
        templateId: this.$route.params.templateId,
        pageSize: itemsPerPage,
        pageNumber: page,
        stage: this.historyStage.toLowerCase()
      };

      if (this.startDateText && this.startDateText.length > 0) {
        params.startDate = this.startDateText;
      }

      if (this.endDateText && this.endDateText.length > 0) {
        params.endDate = this.endDateText;
      }

      if (this.searchEmailTrackingId && this.searchEmailTrackingId.length > 0) {
        params.emailTrackingId = this.searchEmailTrackingId;
      }
      await this.$store.dispatch('templates/getHistory', params);
    },
    async loadReadReceipts() {
      const { page, itemsPerPage } = this.readReceiptOptions;
      const params = {
        emailTrackingId: this.currentHistoricalEmail.emailTrackingId,
        pageSize: itemsPerPage,
        pageNumber: page,
        stage: this.currentHistoricalEmail.stage
      };
      await this.$store.dispatch('templates/getReadReceipts', params);
    },
    getColor(element) {
      switch (element) {
        case 'Error':
          return 'red';
        case 'Sent':
          return 'green';
        case 'Queued':
          return 'blue';
        case 'All Emails Opt-Out':
          return 'amber';
        case 'Processing':
          return 'light-green';
        case 'Sent With Opt-Out':
          return 'purple';
        case 'Failed With MX/Opt-Out Error':
          return 'orange';
        case 'Retrying':
          return 'blue lighten-3';
        default:
          return '';
      }
    },
    copyText(ref) {
      let textToCopy;

      if (!this.$refs[ref]) {
        textToCopy = ref;
      } else {
        textToCopy = this.$refs[ref].innerHTML;
      }

      navigator.clipboard.writeText(textToCopy).then(() => {
        this.$snotify.success(`Successfully copied: ${textToCopy}`);
      });
    },
    getApiEnvironments() {
      var env = stringUtils.capitalizeFirstLetter(process.env.VUE_APP_ENV);
      return envConstants.apiEnvironmentsByEnvironment[env];
    },
    getTemplateStages() {
      return envConstants.templateStagesByApiEnvironment[this.historyApiEnvironment];
    }
  }
};
